// require('../../../js/bootstrap');

// // nav class change

// $('.navbar-collapse').on('shown.bs.collapse', function () {

//     $('body').addClass('slide-out');
// })

// $('.navbar-collapse').on('hidden.bs.collapse', function () {

//     $('body').removeClass('slide-out');

// })
